import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, Input, Output, inject } from '@angular/core';
import { IComentarios } from 'src/app/app-common/comentarios/icomentarios';
import { ComentarioDTO } from 'src/app/ModelDTO/DTO';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { CommentExtended } from 'src/app/app-common/comentarios/commentExtended';
import { CommentBackendService } from '@api/services/comment-backend.service';
import { ReactionService } from 'src/app/shared/reactions/reaction.service';
import { parseStringReactions } from 'src/app/shared/shared-functions';
import { ThrottledObservableGeneratorData, throttledObservableGenerator } from 'src/app/shared/utils/throttled-obsevable-generator';

export interface ICommentEmoji {
  comment: ComentarioDTO;
  emoji: string;
}
@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  providers: [ReactionService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentComponent {
  @Input() public tooltipReactionText: string;
  @Input() public comentarioHandler: IComentarios;
  @Input() public quillModule: unknown;
  @Output() public edit = new EventEmitter<CommentExtended>();
  @Output() public delete = new EventEmitter<ComentarioDTO>();
  @Output() public save = new EventEmitter<ComentarioDTO>();
  @Output() public commentClicked = new EventEmitter<any>();

  @Input() public set commentExtended(comment: CommentExtended) {
    this.comment = comment;
    this.reactionService.$reactions.set(parseStringReactions(comment.reactions));
  }

  public currentLanguage = this.headerService.currentLanguage;
  public comment: CommentExtended;

  public get reactionTooltipKey(): string {
    return this.reactionService.$reactionLimitReached() ? 'COMMENTS.REACTION_LIMIT_REACHED' : 'COMMENTS.ADD_REACTION';
  }

  private executeClickedEmoji: ThrottledObservableGeneratorData<string>;

  constructor(
    private headerService: HeaderAppService,
    private commentBackendService: CommentBackendService,
    public reactionService: ReactionService,
  ) {
    const destroyRef = inject(DestroyRef);

    this.reactionService.initialize(
      () => this.commentBackendService.getCommentsIdReactions(this.comment.id),
      (dto) => this.commentBackendService.updateCommentsIdReactions(this.comment.id, dto),
      (emoji) => this.commentBackendService.deleteCommentsIdReactionsEMOJIID(this.comment.id, emoji),
    );

    this.executeClickedEmoji = throttledObservableGenerator<string>((emoji) => this.reactionService.handleReaction(emoji), destroyRef);
  }

  public saveComment(): void {
    this.save.emit(this.comment);
  }

  public editComment(): void {
    this.edit.emit(this.comment);
  }

  public deleteComment(): void {
    this.delete.emit(this.comment);
  }

  public clickComment($event: any): void {
    this.commentClicked.emit($event);
  }

  public clickedEmoji(emoji: string): void {
    this.executeClickedEmoji.executionSubject$.next(emoji);
  }
}
