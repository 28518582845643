import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { AusenciaDTO } from 'src/app/ModelDTO/DTO/ausencia.DTO';
import { TipoAusencia } from 'src/app/ModelDTO/DTO/tipoAusencia.ENUM';
import { ConvenioColectivoDTO } from 'src/app/ModelDTO/DTO/convenioColectivo.DTO';
import { ConvenioColectivoModelDTO } from 'src/app/ModelDTO/convenioColectivo.ModelDTO';
import { TipoUnidadAusencia } from 'src/app/ModelDTO/DTO/tipoUnidadAusencia.ENUM';
import { TipoGeneracionAusencia } from 'src/app/ModelDTO/DTO/tipoGeneracionAusencia.ENUM';
import { TipoDiasConteo } from 'src/app/ModelDTO/DTO/tipoDiasConteo.ENUM';
import { TipoLimiteAusencia } from 'src/app/ModelDTO/DTO/tipoLimiteAusencia.ENUM';
import { TipoVencimientoAusencia } from 'src/app/ModelDTO/DTO/tipoVencimientoAusencia.ENUM';
import { TipoConteoAntiguedad } from 'src/app/ModelDTO/DTO/tipoConteoAntiguedad.ENUM';
import { TipoCargaFamiliar } from 'src/app/ModelDTO/DTO/tipoCargaFamiliar.ENUM';


export class AusenciaModelDTO extends EntityModelDTO<AusenciaDTO> {

   private convenioModel: ConvenioColectivoModelDTO;
   private convenioSubscribe: Subscription;

   public constructor(protected entityDTO: AusenciaDTO) {
      super(entityDTO);
   }

   public static getTipoAusencia(): string[] {
      return EntityModelDTO.getEnumArray(TipoAusencia);
   }

   public static getTipoUnidadAusencia(): string[] {
      return EntityModelDTO.getEnumArray(TipoUnidadAusencia);
   }

   public static getTipoGeneracionAusencia(): string[] {
      return EntityModelDTO.getEnumArray(TipoGeneracionAusencia);
   }

   public static getTipoDiasConteo(): string[] {
      return EntityModelDTO.getEnumArray(TipoDiasConteo);
   }

   public static getTipoLimiteAusencia(): string[] {
      return EntityModelDTO.getEnumArray(TipoLimiteAusencia);
   }

   public static getTipoVencimientoAusencia(): string[] {
      return EntityModelDTO.getEnumArray(TipoVencimientoAusencia);
   }

   public static getTipoConteoAntiguedad(): string[] {
      return EntityModelDTO.getEnumArray(TipoConteoAntiguedad);
   }

   public static getTipoCargaFamiliar(): string[] {
      return EntityModelDTO.getEnumArray(TipoCargaFamiliar);
   }

   public setEntityDTO(entityDTO: AusenciaDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.convenioModel = new ConvenioColectivoModelDTO(this.entityDTO.convenio);
      this.convenioSubscribe = this.convenioModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.convenioModel.dispose();
      this.convenioSubscribe.unsubscribe();
   }

   get Tipo(): string { return TipoAusencia[this.entityDTO.tipo]; }
   set Tipo(value: string) { this.notifyChangeDTO('tipo', TipoAusencia[value]); }

   get Nombre(): string { return this.entityDTO.nombre; }
   set Nombre(value: string) { this.notifyChangeDTO('nombre', value); }

   get Observacion(): string { return this.entityDTO.observacion; }
   set Observacion(value: string) { this.notifyChangeDTO('observacion', value); }

   get Letra(): string { return this.entityDTO.letra; }
   set Letra(value: string) { this.notifyChangeDTO('letra', value); }

   get ConvenioModel(): ConvenioColectivoModelDTO { return this.convenioModel; }
   get Convenio(): ConvenioColectivoDTO { return this.convenioModel.getEntityDTO(); }
   set Convenio(value: ConvenioColectivoDTO) { this.notifyChange(() => { this.entityDTO.convenio = value; this.convenioModel.setEntityDTO(value); }); }

   get GozaHaberes(): boolean { return this.entityDTO.gozaHaberes; }
   set GozaHaberes(value: boolean) { this.notifyChangeDTO('gozaHaberes', value); }

   get Unidad(): string { return TipoUnidadAusencia[this.entityDTO.unidad]; }
   set Unidad(value: string) { this.notifyChangeDTO('unidad', TipoUnidadAusencia[value]); }

   get CantUnidades(): number { return this.entityDTO.cantUnidades; }
   set CantUnidades(value: number) { this.notifyChangeDTO('cantUnidades', value); }

   get TipoGeneracion(): string { return TipoGeneracionAusencia[this.entityDTO.tipoGeneracion]; }
   set TipoGeneracion(value: string) { this.notifyChangeDTO('tipoGeneracion', TipoGeneracionAusencia[value]); }

   get TipoConteo(): string { return TipoDiasConteo[this.entityDTO.tipoConteo]; }
   set TipoConteo(value: string) { this.notifyChangeDTO('tipoConteo', TipoDiasConteo[value]); }

   get ComienzoCicloMes(): number { return this.entityDTO.comienzoCicloMes; }
   set ComienzoCicloMes(value: number) { this.notifyChangeDTO('comienzoCicloMes', value); }

   get ComienzoCicloDia(): number { return this.entityDTO.comienzoCicloDia; }
   set ComienzoCicloDia(value: number) { this.notifyChangeDTO('comienzoCicloDia', value); }

   get FinCicloMes(): number { return this.entityDTO.finCicloMes; }
   set FinCicloMes(value: number) { this.notifyChangeDTO('finCicloMes', value); }

   get FinCicloDia(): number { return this.entityDTO.finCicloDia; }
   set FinCicloDia(value: number) { this.notifyChangeDTO('finCicloDia', value); }

   get Limite(): string { return TipoLimiteAusencia[this.entityDTO.limite]; }
   set Limite(value: string) { this.notifyChangeDTO('limite', TipoLimiteAusencia[value]); }

   get MaximoAcumulable(): number { return this.entityDTO.maximoAcumulable; }
   set MaximoAcumulable(value: number) { this.notifyChangeDTO('maximoAcumulable', value); }

   get TipoVencimiento(): string { return TipoVencimientoAusencia[this.entityDTO.tipoVencimiento]; }
   set TipoVencimiento(value: string) { this.notifyChangeDTO('tipoVencimiento', TipoVencimientoAusencia[value]); }

   get LimiteVtoAusencia(): number { return this.entityDTO.limiteVtoAusencia; }
   set LimiteVtoAusencia(value: number) { this.notifyChangeDTO('limiteVtoAusencia', value); }

   get ConteoAntiguedad(): string { return TipoConteoAntiguedad[this.entityDTO.conteoAntiguedad]; }
   set ConteoAntiguedad(value: string) { this.notifyChangeDTO('conteoAntiguedad', TipoConteoAntiguedad[value]); }

   get AntiguedadDesde(): number { return this.entityDTO.antiguedadDesde; }
   set AntiguedadDesde(value: number) { this.notifyChangeDTO('antiguedadDesde', value); }

   get AntiguedadHasta(): number { return this.entityDTO.antiguedadHasta; }
   set AntiguedadHasta(value: number) { this.notifyChangeDTO('antiguedadHasta', value); }

   get CargaFamiliar(): string { return TipoCargaFamiliar[this.entityDTO.cargaFamiliar]; }
   set CargaFamiliar(value: string) { this.notifyChangeDTO('cargaFamiliar', TipoCargaFamiliar[value]); }

   get CorrespondeAColaborador(): boolean { return this.entityDTO.correspondeAColaborador; }
   set CorrespondeAColaborador(value: boolean) { this.notifyChangeDTO('correspondeAColaborador', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
