import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class ConfiguracionPermisosDTO implements IEntityDTO {

   fichaje: boolean;
   firmaElectronica: boolean;
   compliance: boolean;
   todosVenAusenciasDelEquipo: boolean;
   colaboradorVeDatosDeContactoAjenos: boolean;
   colaboradorVeSaldosDeAusencias: boolean;
   colaboradorVeOrganigrama: boolean;
   administrativoVeOrganigrama: boolean;
   supervisorVeOrganigrama: boolean;
   soloFirmaVistaEmpresa: boolean;
   soloFirmaVistaColaborador: boolean;
   controlSolicitudLicenciaColaborador: boolean;
   colaboradorVeGestionDelTiempo: boolean;
   colaboradorPuedeIngresarHorasManual: boolean;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.fichaje != null) { this.fichaje = jsonObj.fichaje; }
      if (jsonObj.firmaElectronica != null) { this.firmaElectronica = jsonObj.firmaElectronica; }
      if (jsonObj.compliance != null) { this.compliance = jsonObj.compliance; }
      if (jsonObj.todosVenAusenciasDelEquipo != null) { this.todosVenAusenciasDelEquipo = jsonObj.todosVenAusenciasDelEquipo; }
      if (jsonObj.colaboradorVeDatosDeContactoAjenos != null) { this.colaboradorVeDatosDeContactoAjenos = jsonObj.colaboradorVeDatosDeContactoAjenos; }
      if (jsonObj.colaboradorVeSaldosDeAusencias != null) { this.colaboradorVeSaldosDeAusencias = jsonObj.colaboradorVeSaldosDeAusencias; }
      if (jsonObj.colaboradorVeOrganigrama != null) { this.colaboradorVeOrganigrama = jsonObj.colaboradorVeOrganigrama; }
      if (jsonObj.administrativoVeOrganigrama != null) { this.administrativoVeOrganigrama = jsonObj.administrativoVeOrganigrama; }
      if (jsonObj.supervisorVeOrganigrama != null) { this.supervisorVeOrganigrama = jsonObj.supervisorVeOrganigrama; }
      if (jsonObj.soloFirmaVistaEmpresa != null) { this.soloFirmaVistaEmpresa = jsonObj.soloFirmaVistaEmpresa; }
      if (jsonObj.soloFirmaVistaColaborador != null) { this.soloFirmaVistaColaborador = jsonObj.soloFirmaVistaColaborador; }
      if (jsonObj.controlSolicitudLicenciaColaborador != null) { this.controlSolicitudLicenciaColaborador = jsonObj.controlSolicitudLicenciaColaborador; }
      if (jsonObj.colaboradorVeGestionDelTiempo != null) { this.colaboradorVeGestionDelTiempo = jsonObj.colaboradorVeGestionDelTiempo; }
      if (jsonObj.colaboradorPuedeIngresarHorasManual != null) { this.colaboradorPuedeIngresarHorasManual = jsonObj.colaboradorPuedeIngresarHorasManual; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
