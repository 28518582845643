import { DestroyRef, Directive, ElementRef, HostListener, Input, inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { throttledObservableGenerator } from 'src/app/shared/utils/throttled-obsevable-generator';

export type ThrotteableAction = (...args: unknown[]) => Observable<unknown>;

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[throttledExecution]',
  exportAs: 'throttledExecution',
  standalone: true,
})
export class ThrottledDirective {
  @Input() public throttledExecutionParams: unknown | undefined;
  @Input() public enterKeyDownListener = false;
  @Input() public enterPreventDefault = false;
  @Input() public stopEventPropagation = false;

  @Input() public set throttledExecution(value: ThrotteableAction) {
    if (value) {
      const { executionSubject$, isExecuting$ } = throttledObservableGenerator(value, this.destroyRef);

      this.executionSubject$ = executionSubject$;
      this.isExecuting$ = isExecuting$;
    }
  }

  @HostListener('ngSubmit') protected onNgSubmit(): void {
    this.executionSubject$?.next(this.throttledExecutionParams);
  }

  @HostListener('click', ['$event.target', '$event']) protected onClick(target: HTMLElement, $event: MouseEvent): void {
    if (this.stopEventPropagation) {
      $event.stopPropagation();
    }
    // Check if the click event originated from a button
    let targetElement: HTMLElement | null = target;
    while (targetElement) {
      if (targetElement.tagName.toLowerCase() === 'button') {
        if (targetElement === this.elRef.nativeElement) {
          // Handle the click event originating from a button or its descendants
          this.executionSubject$?.next(this.throttledExecutionParams);
        }
        return;
      }
      targetElement = targetElement.parentElement;
    }
  }

  @HostListener('keydown', ['$event']) protected onEnter(event: KeyboardEvent): void {
    if (event.key === 'Enter' && this.enterKeyDownListener && !event.shiftKey && !event.repeat) {
      this.executionSubject$?.next(this.throttledExecutionParams);
      if (this.enterPreventDefault) {
        event.preventDefault();
      }
      if (this.stopEventPropagation) {
        event.stopPropagation();
      }
    }
  }

  public isExecuting$: Observable<boolean> | undefined;

  private destroyRef = inject(DestroyRef);
  private executionSubject$: Subject<unknown> | undefined;
  private readonly elRef = inject<ElementRef<HTMLElement>>(ElementRef);
}
