import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { NotificationService } from 'src/app/shared/lib/ngx-neo-components-mat/public_api';
import { ActionType } from '../../models';
import { NewNotificationDTO } from '../../models/DTO/newNotification.DTO';
import { NotificationDTO } from '../../models/DTO/notification.DTO';
import { NotificationPriority } from '../../models/DTO/notificationPriority.ENUM';
import { NotificationState } from '../../models/DTO/notificationState.ENUM';
import { ServiceChangeDTO } from '../../models/DTO/serviceChange.DTO';
import { UserDTO } from '../../models/DTO/user.DTO';
import { UsersServiceBackend } from '../backend';
import { NotificationServiceBackend } from '../backend/notification.ServiceBackend';
import { PushService } from './signalr.push.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationSharedService extends NotificationService {
  public pageNumber = 0;
  private getNotificationsSubject = new Subject<NotificationDTO[]>();
  public getNotifications$ = this.getNotificationsSubject.asObservable();

  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  public isLoading$ = this.isLoadingSubject.asObservable();

  private getMoreNotificationsSubject = new Subject<NotificationDTO[]>();
  public getMoreNotifications$ = this.getMoreNotificationsSubject.asObservable();

  private newNotificationSubject = new Subject<any>();
  public newNotification$ = this.newNotificationSubject.asObservable();

  public notifications: NotificationDTO[] = [];
  public usersAdministrative: UserDTO[];
  public notificationMaxPriority: NotificationPriority = NotificationPriority.Low;

  constructor(
    protected notificationServiceBackend: NotificationServiceBackend,
    protected usersServiceBackend: UsersServiceBackend,
    protected pushService: PushService,
    private headerService: HeaderAppService
  ) {
    super();

    this.pushService.registerPushFrom<NotificationDTO>('NewNotification', (notification) => {
      this.addNewNotificationIfSameTenant(notification);
    });

    this.pushService.registerPushFrom<ServiceChangeDTO>('ServiceChange', (serviceChange) => {
      if (serviceChange.action === ActionType.Delete) {
        this.notifications = this.notifications.filter((x) => x.id != serviceChange.id1);
      } else if (serviceChange.serviceNotify.startsWith('/user/notifications/clearNumberInBell')) {
        this.notificationsNotSeen = 0;
      }
    });

    this.pushService.onConectedToServer(async (data) => {
      if (data && !this.headerService.IsTerminal && this.pushService.shouldExcecuteReconnect) {
        await this.getAllNotification();
      }
    });
  }

  public addNewNotificationIfSameTenant(notification: any): NotificationDTO {
    if (this.headerService.tenant && notification.tenant && this.headerService.tenant !== notification.tenant) {
      return null;
    }
    let notificationDTO = this.notifications.find((x) => x.id === notification.id);
    if (!notificationDTO) {
      notificationDTO = new NotificationDTO();
    }
    const isNewNotification = notificationDTO.id === 0;
    notificationDTO.PrepareDTO(notification);
    if (isNewNotification) {
      this.notifications.unshift(notificationDTO);
      this.notificationsNotSeen++;
      this.notificationPriorityAnalyzer();
      this.newNotificationSubject.next(notificationDTO);
    }
    return notificationDTO;
  }

  public async getAllNotification(): Promise<void> {
    this.isLoadingSubject.next(true);
    const notificationsData = await this.notificationServiceBackend.getUserNotifications(false, 0, 20, -1, true);
    this.notificationsNotSeen = notificationsData.notificationsNotSeen;
    this.notifications = notificationsData.data;

    this.notificationPriorityAnalyzer();

    this.getNotificationsSubject.next(this.notifications);
    this.isLoadingSubject.next(false);
  }

  private notificationPriorityAnalyzer(): void {
    this.notificationMaxPriority = NotificationPriority.Low;
    for (const element of this.notifications) {
      if (element.state !== NotificationState.NotRead) {
        continue;
      }
      if (element.priority === NotificationPriority.High) {
        this.notificationMaxPriority = NotificationPriority.High;
        break;
      } else if (element.priority === NotificationPriority.Medium) {
        this.notificationMaxPriority = NotificationPriority.Medium;
      }
    }
  }

  public async createNewNotification(title: string, details: string, usersName: string[]): Promise<void> {
    const notificationDTO = new NotificationDTO();
    notificationDTO.title = title;
    notificationDTO.details = details;

    const newNotificationDTO = new NewNotificationDTO();
    newNotificationDTO.notification = notificationDTO;
    newNotificationDTO.usersName = usersName;

    await this.notificationServiceBackend.insertUserNotifications(newNotificationDTO);
  }

  public async createNewNotificationToUserTypeId(title: string, details: string, userTypeId: number): Promise<void> {
    const notificationDTO = new NotificationDTO();
    notificationDTO.title = title;
    notificationDTO.details = details;

    const newNotificationDTO = new NewNotificationDTO();
    newNotificationDTO.notification = notificationDTO;

    await this.notificationServiceBackend.insertUserNotificationsUserTypeIdId(userTypeId, newNotificationDTO);
  }

  public async readNotification(notificationDTO: NotificationDTO): Promise<void> {
    const readedNotification = await this.notificationServiceBackend.updateUserNotificationsId(notificationDTO.id, notificationDTO);
    notificationDTO.readDateTime = readedNotification.readDateTime;
    notificationDTO.state = NotificationState.Read;

    this.notificationPriorityAnalyzer();
  }

  public async openedNotification(notificationDTO: NotificationDTO): Promise<void> {
    const readedNotification = await this.notificationServiceBackend.updateUserNotificationsIdOpened(notificationDTO.id, notificationDTO);
    notificationDTO.openDateTime = readedNotification.openDateTime;

    this.notificationPriorityAnalyzer();
  }

  public async understoodNotification(notificationDTO: NotificationDTO): Promise<void> {
    const readedNotification = await this.notificationServiceBackend.updateUserNotificationsIdUnderstood(
      notificationDTO.id,
      notificationDTO
    );
    notificationDTO.understoodDateTime = readedNotification.understoodDateTime;
    notificationDTO.state = NotificationState.Read;
    notificationDTO.notUnderstood = false;

    this.notificationPriorityAnalyzer();
  }

  public async notUnderstoodNotification(notificationDTO: NotificationDTO): Promise<void> {
    await this.notificationServiceBackend.updateUserNotificationsIdNotUnderstood(notificationDTO.id, notificationDTO);
    notificationDTO.understoodDateTime = null;
    notificationDTO.notUnderstood = true;
    notificationDTO.state = NotificationState.Read;

    this.notificationPriorityAnalyzer();
  }

  public async archivedNotification(notificationDTO: NotificationDTO): Promise<void> {
    await this.notificationServiceBackend.deleteUserNotificationsId(notificationDTO.id);
    notificationDTO.state = NotificationState.Archived;

    this.notificationPriorityAnalyzer();
  }

  public async unArchivedNotification(notificationDTO: NotificationDTO): Promise<void> {
    await this.notificationServiceBackend.updateUserNotificationsId(notificationDTO.id, notificationDTO, true);
    notificationDTO.state = NotificationState.Read;

    this.notificationPriorityAnalyzer();
  }

  public async ClearBell(): Promise<void> {
    await this.notificationServiceBackend.insertUserNotificationsClearNumberInBell();
    this.notificationsNotSeen = 0;
  }
}
