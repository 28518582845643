import { Injectable } from '@angular/core';
import { CandidatoPostuladoOfertaDTO, CandidatoPostuladoUserValidationDTO } from 'src/app/ModelDTO/DTO';
import { ReclutamientoOfertaPublicaDTO } from 'src/app/ModelDTO/DTO/reclutamientoOfertaPublica.DTO';
import { ReclutamientoPublicoServiceBackend } from 'src/app/ServiceBackend/reclutamientoPublico.ServiceBackend';
import { ImageEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models';

@Injectable()
export class ReclutamientoPublicoService {
  constructor(private reclutamientoOfertaPublicoServiceBackend: ReclutamientoPublicoServiceBackend) {}

  public obtenerOfertaPublica(token: string): Promise<ReclutamientoOfertaPublicaDTO> {
    return this.reclutamientoOfertaPublicoServiceBackend.getReclutamientoPublicoReclutamientoOfertaLINK(token);
  }

  public async updatePostulatedCandidate(
    id: number,
    userToken: string,
    candidateData: FormData,
    imageEntityDTO: ImageEntityDTO,
    imageToken: string,
  ): Promise<CandidatoPostuladoOfertaDTO> {
    const res = await this.reclutamientoOfertaPublicoServiceBackend.updateReclutamientoPublicoId(id, userToken, candidateData);
    if (res && imageEntityDTO && imageEntityDTO.image) {
      await this.reclutamientoOfertaPublicoServiceBackend.insertReclutamientoPublicoIdProfilePicture(
        res.id,
        userToken,
        imageToken,
        imageEntityDTO,
      );
    }
    return res;
  }

  public async insertPostulatedCandidate(
    userToken: string,
    candidateData: FormData,
    imageEntityDTO: ImageEntityDTO,
    imageToken: string,
  ): Promise<CandidatoPostuladoOfertaDTO> {
    const res = await this.reclutamientoOfertaPublicoServiceBackend.insertReclutamientoPublico(userToken, candidateData);
    if (res && imageEntityDTO.image) {
      await this.reclutamientoOfertaPublicoServiceBackend.insertReclutamientoPublicoIdProfilePicture(
        res.id,
        userToken,
        imageToken,
        imageEntityDTO,
      );
    }
    return res;
  }

  public async getPostulatedCandidateAuthenticatedUser(userToken: string): Promise<CandidatoPostuladoOfertaDTO> {
    const res = await this.reclutamientoOfertaPublicoServiceBackend.getReclutamientoPublicoAuthenticatedUsersUSERTOKEN(userToken);
    return res;
  }

  public async insertPostulatedCandidateValidateEmail(candidate: CandidatoPostuladoUserValidationDTO): Promise<void> {
    const res = await this.reclutamientoOfertaPublicoServiceBackend.insertReclutamientoPublicoValidateEmail(candidate);
    return res;
  }
}
