/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { convertJsonDates } from 'src/app/shared/shared-functions';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs'
import { Constants } from 'src/app/shared/constants';
import { DataDTO } from 'src/app/api/interfaces/data.interface';

import { AuditLogEntryDTO } from 'src/app/api/interfaces/audit-log-entry.interface';
import { ListOfIdsDTO } from 'src/app/api/interfaces/list-of-ids.interface';
import { NewNotificationDTO } from 'src/app/api/interfaces/new-notification.interface';
import { NotificationAttachmentsDTO } from 'src/app/api/interfaces/notification-attachments.interface';
import { NotificationDataDTO } from 'src/app/api/interfaces/notification-data.interface';
import { NotificationDTO } from 'src/app/api/interfaces/notification.interface';
import { NotificationStatsDTO } from 'src/app/api/interfaces/notification-stats.interface';
import { UserDTO } from 'src/app/api/interfaces/user.interface';

@Injectable({
   providedIn: 'root'
})
export class NotificationBackendService {

   constructor(protected http: HttpClient) { }


   public getUserNotificationsIdAuditory(id: number): Observable<AuditLogEntryDTO[]> {
      return this.http.get<DataDTO<AuditLogEntryDTO>>(Constants.apiURL + '/user/notifications/' + id + '/Auditory').pipe(map((res) => convertJsonDates(res.data) as AuditLogEntryDTO[]));
   }

   public getUserNotifications(params: { archived: boolean, pageNumber: number, pageSize: number, tipo?: number, includeRead?: boolean, priorityDescOrder?: boolean, onlyCommunications?: boolean }, ): Observable<NotificationDataDTO> {
      const { archived, pageNumber, pageSize, tipo = -1, includeRead = false, priorityDescOrder = false, onlyCommunications = undefined } = params;
      return this.http.get<NotificationDataDTO>(Constants.apiURL + '/user/notifications/' + '?archived=' + archived +
            '&pageNumber=' + pageNumber +
            '&pageSize=' + pageSize +
            '&tipo=' + tipo +
            '&includeRead=' + includeRead +
            '&priorityDescOrder=' + priorityDescOrder +
            '&onlyCommunications=' + onlyCommunications).pipe(map((res) => convertJsonDates(res) as NotificationDataDTO ));
   }

   public getUserNotificationsTipo(params: { archived: boolean, pageNumber: number, pageSize: number, tipo?: number }, ): Observable<NotificationDataDTO> {
      const { archived, pageNumber, pageSize, tipo = -1 } = params;
      return this.http.get<NotificationDataDTO>(Constants.apiURL + '/user/notifications/tipo' + '?archived=' + archived +
            '&pageNumber=' + pageNumber +
            '&pageSize=' + pageSize +
            '&tipo=' + tipo).pipe(map((res) => convertJsonDates(res) as NotificationDataDTO ));
   }

   public getUserNotificationsStaticsByType(type: number = -1, archived: boolean = true, collaboratorView: boolean = false): Observable<NotificationStatsDTO[]> {
      return this.http.get<DataDTO<NotificationStatsDTO>>(Constants.apiURL + '/user/notifications/staticsByType' + '?type=' + type +
            '&archived=' + archived +
            '&collaboratorView=' + collaboratorView).pipe(map((res) => convertJsonDates(res.data) as NotificationStatsDTO[]));
   }

   public getUserNotificationsLast(type: number = -1): Observable<NotificationDTO> {
      return this.http.get<NotificationDTO>(Constants.apiURL + '/user/notifications/last' + '?type=' + type).pipe(map((res) => convertJsonDates(res) as NotificationDTO ));
   }

   public getUserNotificationsTotalWithoutRead(type: number = -1): Observable<NotificationDataDTO> {
      return this.http.get<NotificationDataDTO>(Constants.apiURL + '/user/notifications/totalWithoutRead' + '?type=' + type).pipe(map((res) => convertJsonDates(res) as NotificationDataDTO ));
   }

   public insertUserNotifications(newNotificationDTO: NewNotificationDTO): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/user/notifications/', newNotificationDTO);
   }
   public insertUserNotificationsUserTypeVALUE(vALUE: number, notificationDTO: NotificationDTO): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/user/notifications/userType/' + vALUE, notificationDTO);
   }
   public insertUserNotificationsUserTypeIdId(id: number, newNotificationDTO: NewNotificationDTO): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/user/notifications/userTypeId/' + id, newNotificationDTO);
   }
   public insertUserNotificationsClearNumberInBell(): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/user/notifications/clearNumberInBell', null);
   }
   public updateUserNotificationsId(id: number, notificationDTO: NotificationDTO, unArchive: boolean = false): Observable<NotificationDTO> {
      return this.http.put<NotificationDTO>(Constants.apiURL + '/user/notifications/' + id + '?unArchive=' + unArchive, notificationDTO).pipe(map((res) => convertJsonDates(res) as NotificationDTO ));
   }

   public updateUserNotificationsStatsId(id: number): Observable<NotificationDTO> {
      return this.http.put<NotificationDTO>(Constants.apiURL + '/user/notifications/stats/' + id, null).pipe(map((res) => convertJsonDates(res) as NotificationDTO ));
   }

   public updateUserNotificationsIdOpened(id: number, notificationDTO: NotificationDTO, unArchive: boolean = false): Observable<NotificationDTO> {
      return this.http.put<NotificationDTO>(Constants.apiURL + '/user/notifications/' + id + '/opened' + '?unArchive=' + unArchive, notificationDTO).pipe(map((res) => convertJsonDates(res) as NotificationDTO ));
   }

   public updateUserNotificationsIdUnderstood(id: number, notificationDTO: NotificationDTO, unArchive: boolean = false): Observable<NotificationDTO> {
      return this.http.put<NotificationDTO>(Constants.apiURL + '/user/notifications/' + id + '/understood' + '?unArchive=' + unArchive, notificationDTO).pipe(map((res) => convertJsonDates(res) as NotificationDTO ));
   }

   public updateUserNotificationsIdNotUnderstood(id: number, notificationDTO: NotificationDTO, unArchive: boolean = false): Observable<NotificationDTO> {
      return this.http.put<NotificationDTO>(Constants.apiURL + '/user/notifications/' + id + '/notUnderstood' + '?unArchive=' + unArchive, notificationDTO).pipe(map((res) => convertJsonDates(res) as NotificationDTO ));
   }

   public updateUserNotificationsArchive(listOfIdsDTO: ListOfIdsDTO): Observable<void> {
      return this.http.put<void>(Constants.apiURL + '/user/notifications/archive', listOfIdsDTO);
   }
   public deleteUserNotificationsId(id: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/user/notifications/' + id);
   }
   public deleteUserNotificationsStatsId(id: number, permanently: boolean): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/user/notifications/stats/' + id + '?permanently=' + permanently);
   }
   public getUserNotificationsId(id: number): Observable<NotificationAttachmentsDTO> {
      return this.http.get<NotificationAttachmentsDTO>(Constants.apiURL + '/user/notifications/' + id).pipe(map((res) => convertJsonDates(res) as NotificationAttachmentsDTO ));
   }

   public getUserNotificationsIdNotification(id: number): Observable<NotificationAttachmentsDTO> {
      return this.http.get<NotificationAttachmentsDTO>(Constants.apiURL + '/user/notifications/' + id + '/notification').pipe(map((res) => convertJsonDates(res) as NotificationAttachmentsDTO ));
   }

   public getUserNotificationsNotificationId(id: number): Observable<NotificationAttachmentsDTO> {
      return this.http.get<NotificationAttachmentsDTO>(Constants.apiURL + '/user/notifications/notification/' + id).pipe(map((res) => convertJsonDates(res) as NotificationAttachmentsDTO ));
   }
}
