import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom, map } from 'rxjs';
import { AddresseesDTO } from 'src/app/ModelDTO/DTO/addressees.DTO';
import { ComentarioDTO } from 'src/app/ModelDTO/DTO/comentario.DTO';
import { AuditLogEntryDTO, DataDTO, NameDetailDTO, NotificationDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { NewCommentReactionDTO } from 'src/app/ModelDTO/DTO/newCommentReaction.DTO';
import { CommentReactionDTO } from 'src/app/ModelDTO/DTO/commentReaction.DTO';
import { CurrentReactionDTO } from '@api/interfaces/current-reaction.interface';

@Injectable({
   providedIn: 'root'
})
export class ComunicadoServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertNotificationsIdUsers(id: number, addresseesDTO: AddresseesDTO): Promise<Array<NotificationDTO>> {

         const res = await lastValueFrom(this.http.post<DataDTO>(Constants.apiURL + '/notifications/' + id + '/users', addresseesDTO));
         const resJson = res.data;
         const resDTO = new Array<NotificationDTO>();
         for (const item of resJson) {
            const itemDTO = new NotificationDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getNotificationsIdComentarios(id: number): Promise<Array<ComentarioDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/notifications/' + id + '/comentarios'));
         const resJson = res.data;
         const resDTO = new Array<ComentarioDTO>();
         for (const item of resJson) {
            const itemDTO = new ComentarioDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertNotificationsIdComentarios(id: number, stream: FormData): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/notifications/' + id + '/comentarios', stream));

   }

   public async updateNotificationsIdComentariosIDCOMENTARIO(id: number, iDComentario: number, comentarioDTO: ComentarioDTO): Promise<ComentarioDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/notifications/' + id + '/comentarios/' + iDComentario, comentarioDTO));
         const resDTO = new ComentarioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteNotificationsIdComentariosIDCOMENTARIO(id: number, iDComentario: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/notifications/' + id + '/comentarios/' + iDComentario));

   }

   public async getUserNotificationsIdComentarios(id: number): Promise<Array<ComentarioDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/user/notifications/' + id + '/comentarios'));
         const resJson = res.data;
         const resDTO = new Array<ComentarioDTO>();
         for (const item of resJson) {
            const itemDTO = new ComentarioDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertUserNotificationsIdComentarios(id: number, stream: FormData): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/user/notifications/' + id + '/comentarios', stream));

   }

   public async updateUserNotificationsIdComentariosIDCOMENTARIO(id: number, iDComentario: number, comentarioDTO: ComentarioDTO): Promise<ComentarioDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/user/notifications/' + id + '/comentarios/' + iDComentario, comentarioDTO));
         const resDTO = new ComentarioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteUserNotificationsIdComentariosIDCOMENTARIO(id: number, iDComentario: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/user/notifications/' + id + '/comentarios/' + iDComentario));

   }

   public async insertNotificationsChatGptDescription(nameDetailDTO: NameDetailDTO): Promise<NameDetailDTO> {
         const res =  await lastValueFrom(this.http.post(Constants.apiURL + '/notifications/chat-gpt/description', nameDetailDTO));
         const resDTO = new NameDetailDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateNotificationsId(id: number, notificationDTO: NotificationDTO): Promise<NotificationDTO> {
         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/notifications/' + id, notificationDTO));
         const resDTO = new NotificationDTO();
         resDTO.PrepareDTO(res);
         return resDTO;
   }

   public getNotificationIdReactions(id: number): Observable<CommentReactionDTO[]> {
    return this.http.get<DataDTO>(Constants.apiURL + '/notifications/' + id + '/reactions').pipe(
      map((res) =>
        res.data.map((element) => {
          const resDTO = new CommentReactionDTO();
          resDTO.PrepareDTO(element);
          return resDTO;
        })
      )
    );
  }

  public updateNotificationIdReactions(id: number, newCommentReactionDTO: NewCommentReactionDTO): Observable<CurrentReactionDTO> {
    return this.http.put<CurrentReactionDTO>(Constants.apiURL + '/notifications/' + id + '/reactions', newCommentReactionDTO);
  }

  public deleteNotificationIdReactionsEMOJIID(id: number, emojiId: string): Observable<CurrentReactionDTO> {
    return this.http.delete<CurrentReactionDTO>(Constants.apiURL + '/notifications/' + id + '/reactions/' + emojiId);
  }
}
