import { Component } from '@angular/core';
import { urlAPI, urlCDN, urlWebPage, urlZendesk } from 'src/app/shared/constants';

@Component({
  selector: 'app-footer-public',
  templateUrl: './footer-public.component.html',
  styleUrls: ['./footer-public.component.scss'],
})
export class FooterPublicComponent {
  public readonly urlAPI = urlAPI;
  public readonly urlWebPage = urlWebPage;
  public readonly urlCDN = urlCDN;
  public readonly urlZendesk = urlZendesk;

  public panelOpenState = false;
  public year = new Date().getFullYear();
}
