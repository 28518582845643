import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ReclutamientoEstadoOferta } from 'src/app/ModelDTO/DTO/reclutamientoEstadoOferta.ENUM';
import { ReclutamientoOfertaPublicaDTO } from 'src/app/ModelDTO/DTO/reclutamientoOfertaPublica.DTO';
import { RemunerationPeriod } from 'src/app/ModelDTO/DTO/remunerationPeriod.ENUM';
import { RemunerationType } from 'src/app/ModelDTO/DTO/remunerationType.ENUM';
import { WorkLocationType } from 'src/app/ModelDTO/DTO/workLocationType.ENUM';
import { confidencialImage, urlCDN } from 'src/app/shared/constants';

@Component({
  selector: 'app-email-validation-form',
  templateUrl: './email-validation-form.component.html',
  styleUrls: ['./email-validation-form.component.scss'],
})
export class EmailValidationFormComponent {
  @Input() public publicOffer: ReclutamientoOfertaPublicaDTO;
  @Input() public validateEmailForm: FormGroup;
  @Input() public hideForm: boolean;
  @Output() public validateEmail = new EventEmitter();

  public readonly WorkLocationType = WorkLocationType;
  public readonly ReclutamientoEstadoOferta = ReclutamientoEstadoOferta;
  public readonly RemunerationType: RemunerationType;
  public readonly RemunerationPeriod: RemunerationPeriod;
  public readonly urlCDN = urlCDN;

  public get companyImage(): string {
    return this.publicOffer.companyImage ? this.publicOffer.companyImage : confidencialImage;
  }

  public get remunerationLabel(): string {
    const type =
      this.publicOffer.remunerationType !== RemunerationType.Undefined
        ? this.translate.instant(`ENUM.RECRUITMENT.TYPE.${RemunerationType[this.publicOffer.remunerationType]}`)
        : '';

    const period =
      this.publicOffer.remunerationPeriod !== RemunerationPeriod.Undefined
        ? this.translate.instant(`ENUM.RECRUITMENT.PERIOD.${RemunerationPeriod[this.publicOffer.remunerationPeriod]}`)
        : '';

    return this.translate.instant('RECRUITMENT.REMUNERATION', { type, period });
  }

  constructor(@Inject(DOCUMENT) private document: Document, private translate: TranslateService) {}

  public onSubmit(): void {
    this.validateEmail.emit();
  }

  public scrollEmail(): void {
    this.document.getElementById('email').scrollIntoView({ behavior: 'smooth' });
  }
}
