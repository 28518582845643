import { CurrencyDTO } from 'src/app/ModelDTO/DTO/currency.DTO';
import { LocalidadDTO } from 'src/app/ModelDTO/DTO/localidad.DTO';
import { ModalidadContractualDTO } from 'src/app/ModelDTO/DTO/modalidadContractual.DTO';
import { OficinaDTO } from 'src/app/ModelDTO/DTO/oficina.DTO';
import { PersonalLegajoDTO } from 'src/app/ModelDTO/DTO/personalLegajo.DTO';
import { ReclutamientoEstadoOferta } from 'src/app/ModelDTO/DTO/reclutamientoEstadoOferta.ENUM';
import { ReclutamientoFaseDTO } from 'src/app/ModelDTO/DTO/reclutamientoFase.DTO';
import { ReclutamientoFaseCandidatoDTO } from 'src/app/ModelDTO/DTO/reclutamientoFaseCandidato.DTO';
import { ReclutamientoOfertaCampoDTO } from 'src/app/ModelDTO/DTO/reclutamientoOfertaCampo.DTO';
import { ReclutamientoOfertaConocimientoDTO } from 'src/app/ModelDTO/DTO/reclutamientoOfertaConocimiento.DTO';
import { RemunerationPeriod } from 'src/app/ModelDTO/DTO/remunerationPeriod.ENUM';
import { RemunerationType } from 'src/app/ModelDTO/DTO/remunerationType.ENUM';
import { TipoContratacion } from 'src/app/ModelDTO/DTO/tipoContratacion.ENUM';
import { WorkLocationType } from 'src/app/ModelDTO/DTO/workLocationType.ENUM';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';


export class ReclutamientoOfertaDTO implements IEntityDTO {

   fechaCarga: Date;
   creador: PersonalLegajoDTO;
   titulo = '';
   oficina: OficinaDTO;
   area = '';
   location: WorkLocationType;
   localidad: LocalidadDTO;
   tipoContrato: ModalidadContractualDTO;
   tipoJornada: TipoContratacion;
   salarioAnual: number;
   desde: number;
   hasta: number;
   currency: CurrencyDTO;
   remunerationType: RemunerationType;
   remunerationPeriod: RemunerationPeriod;
   remunerationVisible: boolean;
   descripcion = '';
   descripcionInterna = '';
   esOfertaPublica: boolean;
   esConfidencial: boolean;
   linkPublico = '';
   headerImage = '';
   estado: ReclutamientoEstadoOferta;
   fases: Array<ReclutamientoFaseDTO>;
   campos: Array<ReclutamientoOfertaCampoDTO>;
   conocimientos: Array<ReclutamientoOfertaConocimientoDTO>;
   postulaciones: Array<ReclutamientoFaseCandidatoDTO>;
   cantidadCandidatos = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.creador = new PersonalLegajoDTO();
      this.oficina = new OficinaDTO();
      this.localidad = new LocalidadDTO();
      this.tipoContrato = new ModalidadContractualDTO();
      this.currency = new CurrencyDTO();
      this.fases = new Array<ReclutamientoFaseDTO>();
      this.campos = new Array<ReclutamientoOfertaCampoDTO>();
      this.conocimientos = new Array<ReclutamientoOfertaConocimientoDTO>();
      this.postulaciones = new Array<ReclutamientoFaseCandidatoDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.fechaCarga != null) { this.fechaCarga = new Date(jsonObj.fechaCarga); }
      if (jsonObj.creador != null) { this.creador.PrepareDTO(jsonObj.creador); }
      if (jsonObj.titulo != null) { this.titulo = jsonObj.titulo; }
      if (jsonObj.oficina != null) { this.oficina.PrepareDTO(jsonObj.oficina); }
      if (jsonObj.area != null) { this.area = jsonObj.area; }
      if (jsonObj.location != null) { this.location = jsonObj.location as WorkLocationType; }
      if (jsonObj.localidad != null) { this.localidad.PrepareDTO(jsonObj.localidad); }
      if (jsonObj.tipoContrato != null) { this.tipoContrato.PrepareDTO(jsonObj.tipoContrato); }
      if (jsonObj.tipoJornada != null) { this.tipoJornada = jsonObj.tipoJornada as TipoContratacion; }
      if (jsonObj.salarioAnual != null) { this.salarioAnual = jsonObj.salarioAnual; }
      if (jsonObj.desde != null) { this.desde = jsonObj.desde; }
      if (jsonObj.hasta != null) { this.hasta = jsonObj.hasta; }
      if (jsonObj.currency != null) { this.currency.PrepareDTO(jsonObj.currency); }
      if (jsonObj.remunerationType != null) { this.remunerationType = jsonObj.remunerationType as RemunerationType; }
      if (jsonObj.remunerationPeriod != null) { this.remunerationPeriod = jsonObj.remunerationPeriod as RemunerationPeriod; }
      if (jsonObj.remunerationVisible != null) { this.remunerationVisible = jsonObj.remunerationVisible; }
      if (jsonObj.descripcion != null) { this.descripcion = jsonObj.descripcion; }
      if (jsonObj.descripcionInterna != null) { this.descripcionInterna = jsonObj.descripcionInterna; }
      if (jsonObj.esOfertaPublica != null) { this.esOfertaPublica = jsonObj.esOfertaPublica; }
      if (jsonObj.esConfidencial != null) { this.esConfidencial = jsonObj.esConfidencial; }
      if (jsonObj.linkPublico != null) { this.linkPublico = jsonObj.linkPublico; }
      if (jsonObj.headerImage != null) { this.headerImage = jsonObj.headerImage; }
      if (jsonObj.estado != null) { this.estado = jsonObj.estado as ReclutamientoEstadoOferta; }
      if (jsonObj.fases != null) { for (const item of jsonObj.fases) { const itemDTO = new ReclutamientoFaseDTO(); itemDTO.PrepareDTO(item); this.fases.push(itemDTO); } }
      if (jsonObj.campos != null) { for (const item of jsonObj.campos) { const itemDTO = new ReclutamientoOfertaCampoDTO(); itemDTO.PrepareDTO(item); this.campos.push(itemDTO); } }
      if (jsonObj.conocimientos != null) { for (const item of jsonObj.conocimientos) { const itemDTO = new ReclutamientoOfertaConocimientoDTO(); itemDTO.PrepareDTO(item); this.conocimientos.push(itemDTO); } }
      if (jsonObj.postulaciones != null) { for (const item of jsonObj.postulaciones) { const itemDTO = new ReclutamientoFaseCandidatoDTO(); itemDTO.PrepareDTO(item); this.postulaciones.push(itemDTO); } }
      if (jsonObj.cantidadCandidatos != null) { this.cantidadCandidatos = jsonObj.cantidadCandidatos; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
