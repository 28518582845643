<div class="material-xsmall">
  <button
    class="reaction-button space-button"
    type="button"
    color="primary"
    mat-stroked-button
    (click)="onClick()"
    (mouseenter)="onMouseEnter()"
    [matTooltip]="tooltipReactionText"
  >
    <span class="mr-1">{{ reaction.emoji }}</span>
    <span>{{ reaction.quantity }}</span>
  </button>
</div>
