<div class="d-flex flex-column flex-grow-1 pt-2">
  <form [formGroup]="optionQuestionForm" class="d-flex flex-row align-items-center justify-content-between gap-1">
    <mat-form-field class="flex-fill" appearance="outline">
      <mat-label>{{ 'FORMS.OPTION' | translate }}</mat-label>
      <input (keyup.enter)="addOptionCmd.execute()" #opc matInput type="text" id="opc" name="opc" formControlName="text" />
    </mat-form-field>
    <mat-form-field appearance="outline" [matTooltip]="'FORMS.OPTION_TOOLTIP' | translate">
      <mat-label>{{ 'FORMS.WEIGHT' | translate }}</mat-label>
      <input
        (keyup.enter)="addOptionCmd.execute()"
        #weight
        matInput
        type="number"
        id="weight"
        name="weight"
        formControlName="selectedWeight"
      />
    </mat-form-field>
    <button
      #btnOption
      id="btnOption"
      name="btnOption"
      mat-mini-fab
      type="button"
      color="success"
      [disabled]="!optionQuestionForm.valid"
      [command]="addOptionCmd"
      class="mb-20"
    >
      <mat-icon fontSet="material-symbols-outlined">add</mat-icon>
    </button>
  </form>
  <app-list-options [options]="options" (remove)="removeOption($event)" (edit)="editOption($event)"></app-list-options>
</div>
