import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, Output, inject } from '@angular/core';
import { Reaction } from 'src/app/shared/reactions/reaction';
import { ReactionButtonComponent } from 'src/app/shared/reactions/reaction-button/reaction-button.component';
import { ReactionService } from 'src/app/shared/reactions/reaction.service';
import { ThrottledObservableGeneratorData, throttledObservableGenerator } from 'src/app/shared/utils/throttled-obsevable-generator';

@Component({
  selector: 'app-reaction-list',
  templateUrl: './reaction-list.component.html',
  styleUrls: ['./reaction-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ReactionButtonComponent],
})
export class ReactionListComponent {
  @Output() public emojiClicked = new EventEmitter<string>();

  private executeLoadEmojis: ThrottledObservableGeneratorData<string>;

  constructor(public reactionService: ReactionService) {
    const destroyRef = inject(DestroyRef);

    this.executeLoadEmojis = throttledObservableGenerator<string>((emoji) => this.reactionService.loadEmojiReactions(emoji), destroyRef);
  }

  public onMouseEnter(reaction: Reaction): void {
    this.executeLoadEmojis.executionSubject$.next(reaction.emoji);
  }

  public onClick(reaction: Reaction): void {
    this.emojiClicked.emit(reaction.emoji);
  }
}
