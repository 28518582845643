/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { convertJsonDates } from 'src/app/shared/shared-functions';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable } from 'rxjs'
import { Constants } from 'src/app/shared/constants';
import { DataDTO } from 'src/app/api/interfaces/data.interface';
import { NamedBlobDTO } from 'src/app/api/interfaces/named-blob.dto';

import { AuthResponseDTO } from 'src/app/api/interfaces/auth-response.interface';
import { SignatureType } from 'src/app/api/enums/signature-type.enum';
import { AuditLogEntryDTO } from 'src/app/api/interfaces/audit-log-entry.interface';
import { NotificationDTO } from 'src/app/api/interfaces/notification.interface';
import { AddresseesDTO } from 'src/app/api/interfaces/addressees.interface';
import { AssignmentType } from 'src/app/api/enums/assignment-type.enum';
import { CambioDTO } from 'src/app/api/interfaces/cambio.interface';
import { CambiosHistoricosDTO } from 'src/app/api/interfaces/cambios-historicos.interface';
import { ComentarioDTO } from 'src/app/api/interfaces/comentario.interface';
import { ConfiguracionFirmaDigitalPosicionDTO } from 'src/app/api/interfaces/configuracion-firma-digital-posicion.interface';
import { ContingenciaDTO } from 'src/app/api/interfaces/contingencia.interface';
import { CuentaCorrienteDTO } from 'src/app/api/interfaces/cuenta-corriente.interface';
import { EstadisticasFirmasDTO } from 'src/app/api/interfaces/estadisticas-firmas.interface';
import { FichajeBasicoDTO } from 'src/app/api/interfaces/fichaje-basico.interface';
import { IPersonalOrganigramaDTO } from 'src/app/api/interfaces/i-personal-organigrama.interface';
import { PersonalFeedbackDTO } from 'src/app/api/interfaces/personal-feedback.interface';
import { PersonalFeedbackResumenDTO } from 'src/app/api/interfaces/personal-feedback-resumen.interface';
import { PersonalLegajoBasicoDTO } from 'src/app/api/interfaces/personal-legajo-basico.interface';
import { PersonalLegajoDTO } from 'src/app/api/interfaces/personal-legajo.interface';
import { ProfileEditDTO } from 'src/app/api/interfaces/profile-edit.interface';
import { ReferidoDTO } from 'src/app/api/interfaces/referido.interface';
import { SaldoCuentaCorrienteDTO } from 'src/app/api/interfaces/saldo-cuenta-corriente.interface';
import { TurnosDiaAgenteDTO } from 'src/app/api/interfaces/turnos-dia-agente.interface';

@Injectable({
   providedIn: 'root'
})
export class PersonalLegajoBackendService {

   constructor(protected http: HttpClient) { }


   public getPersonalLegajosIdAuditory(id: number): Observable<AuditLogEntryDTO[]> {
      return this.http.get<DataDTO<AuditLogEntryDTO>>(Constants.apiURL + '/personalLegajos/' + id + '/Auditory').pipe(map((res) => convertJsonDates(res.data) as AuditLogEntryDTO[]));
   }

   public getPersonalLegajos(activos: boolean = false, orgfilterActive: boolean = false): Observable<PersonalLegajoBasicoDTO[]> {
      return this.http.get<DataDTO<PersonalLegajoBasicoDTO>>(Constants.apiURL + '/personalLegajos/' + '?activos=' + activos +
            '&orgfilterActive=' + orgfilterActive).pipe(map((res) => convertJsonDates(res.data) as PersonalLegajoBasicoDTO[]));
   }

   public async getPersonalLegajosXLSX(activos: boolean = false, orgfilterActive: boolean = false, namedBlob: NamedBlobDTO = null): Promise<Blob> {
      const res = await lastValueFrom(this.http.get(Constants.apiURL +
'/personalLegajos/' + '?activos=' + activos +
            '&orgfilterActive=' + orgfilterActive + '&BLOB=XLSX', { observe: 'response', responseType: 'blob' }));
      const resDTO = new Blob([res.body], { type: 'application/text' });
      if (namedBlob) {
         namedBlob.setBlobNameFromHttpResponse(res);
         // eslint-disable-next-line no-param-reassign
         namedBlob.blob = res.body;
      }
      return resDTO;
      }


   public getPersonalLegajosLegajoLEGAJO(legajo: string): Observable<PersonalLegajoBasicoDTO> {
      return this.http.get<PersonalLegajoBasicoDTO>(Constants.apiURL + '/personalLegajos/legajo/' + legajo).pipe(map((res) => convertJsonDates(res) as PersonalLegajoBasicoDTO ));
   }

   public getPersonalLegajosPorCargoidCargo(idCargo: number): Observable<PersonalLegajoBasicoDTO[]> {
      return this.http.get<DataDTO<PersonalLegajoBasicoDTO>>(Constants.apiURL + '/personalLegajos/porCargo/' + idCargo).pipe(map((res) => convertJsonDates(res.data) as PersonalLegajoBasicoDTO[]));
   }

   public getPersonalLegajosPorAreaIDAREA(idArea: number): Observable<PersonalLegajoDTO[]> {
      return this.http.get<DataDTO<PersonalLegajoDTO>>(Constants.apiURL + '/personalLegajos/porArea/' + idArea).pipe(map((res) => convertJsonDates(res.data) as PersonalLegajoDTO[]));
   }

   public getPersonalLegajosFiltered(params: { areaId?: number, sectorId?: number, contractTypeId?: number, officeId?: number }, ): Observable<PersonalLegajoBasicoDTO[]> {
      const { areaId = 0, sectorId = 0, contractTypeId = 0, officeId = 0 } = params;
      return this.http.get<DataDTO<PersonalLegajoBasicoDTO>>(Constants.apiURL + '/personalLegajos/filtered' + '?areaId=' + areaId +
            '&sectorId=' + sectorId +
            '&contractTypeId=' + contractTypeId +
            '&officeId=' + officeId).pipe(map((res) => convertJsonDates(res.data) as PersonalLegajoBasicoDTO[]));
   }

   public getPersonalLegajosAutoComplete(contiene: string, pageSize: number, todos: boolean = false): Observable<PersonalLegajoBasicoDTO[]> {
      return this.http.get<DataDTO<PersonalLegajoBasicoDTO>>(Constants.apiURL + '/personalLegajos/autoComplete' + '?contiene=' + contiene +
            '&pageSize=' + pageSize +
            '&todos=' + todos).pipe(map((res) => convertJsonDates(res.data) as PersonalLegajoBasicoDTO[]));
   }

   public getPersonalLegajosSearch(searchValue: string, all: boolean = false): Observable<PersonalLegajoBasicoDTO[]> {
      return this.http.get<DataDTO<PersonalLegajoBasicoDTO>>(Constants.apiURL + '/personalLegajos/search' + '?searchValue=' + searchValue +
            '&all=' + all).pipe(map((res) => convertJsonDates(res.data) as PersonalLegajoBasicoDTO[]));
   }

   public getPersonalLegajosAutoCompleteDniCuil(contiene: string, pageSize: number): Observable<PersonalLegajoBasicoDTO[]> {
      return this.http.get<DataDTO<PersonalLegajoBasicoDTO>>(Constants.apiURL + '/personalLegajos/autoCompleteDniCuil' + '?contiene=' + contiene +
            '&pageSize=' + pageSize).pipe(map((res) => convertJsonDates(res.data) as PersonalLegajoBasicoDTO[]));
   }

   public updatePersonalLegajosIdCambioRol(id: number, personalLegajoBasicoDTO: PersonalLegajoBasicoDTO): Observable<void> {
      return this.http.put<void>(Constants.apiURL + '/personalLegajos/' + id + '/cambioRol', personalLegajoBasicoDTO);
   }
   public updatePersonalLegajosIdEstadoOwner(id: number, personalLegajoBasicoDTO: PersonalLegajoBasicoDTO): Observable<void> {
      return this.http.put<void>(Constants.apiURL + '/personalLegajos/' + id + '/estadoOwner', personalLegajoBasicoDTO);
   }
   public insertPersonalLegajosIdInvalidarToken(id: number): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/personalLegajos/' + id + '/invalidarToken', null);
   }
   public getPersonalLegajosIdContingencia(id: number): Observable<ContingenciaDTO> {
      return this.http.get<ContingenciaDTO>(Constants.apiURL + '/personalLegajos/' + id + '/contingencia').pipe(map((res) => convertJsonDates(res) as ContingenciaDTO ));
   }

   public getPersonalLegajosIdHistorialCambios(id: number): Observable<CambiosHistoricosDTO> {
      return this.http.get<CambiosHistoricosDTO>(Constants.apiURL + '/personalLegajos/' + id + '/historialCambios').pipe(map((res) => convertJsonDates(res) as CambiosHistoricosDTO ));
   }

   public updatePersonalLegajosIdHistorialCambiosIDCambio(id: number, iDCambio: number, cambioDTO: CambioDTO): Observable<CambioDTO> {
      return this.http.put<CambioDTO>(Constants.apiURL + '/personalLegajos/' + id + '/historialCambios/' + iDCambio, cambioDTO).pipe(map((res) => convertJsonDates(res) as CambioDTO ));
   }

   public deletePersonalLegajosIdHistorialCambiosIDCambio(id: number, iDCambio: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/personalLegajos/' + id + '/historialCambios/' + iDCambio);
   }
   public getPersonalLegajosTurnosPropiosFECHA(fecha: string): Observable<TurnosDiaAgenteDTO[]> {
      return this.http.get<DataDTO<TurnosDiaAgenteDTO>>(Constants.apiURL + '/personalLegajos/turnosPropios/' + fecha).pipe(map((res) => convertJsonDates(res.data) as TurnosDiaAgenteDTO[]));
   }

   public insertPersonalLegajosNotificar(notificationToPersonalsDTO: FormData): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/personalLegajos/notificar/', notificationToPersonalsDTO);
   }
   public getPersonalLegajosFiltradoAreaIDAREASectorIDSECTOR(iDAREA: number, iDSECTOR: number): Observable<PersonalLegajoBasicoDTO[]> {
      return this.http.get<DataDTO<PersonalLegajoBasicoDTO>>(Constants.apiURL + '/personalLegajos/filtrado/area/' + iDAREA + '/sector/' + iDSECTOR).pipe(map((res) => convertJsonDates(res.data) as PersonalLegajoBasicoDTO[]));
   }

   public getPersonalLegajosOrganigrama(params: { area?: number, sector?: number, oficina?: number, equipo?: boolean }, ): Observable<IPersonalOrganigramaDTO> {
      const { area = 0, sector = 0, oficina = 0, equipo = false } = params;
      return this.http.get<IPersonalOrganigramaDTO>(Constants.apiURL + '/personalLegajos/organigrama' + '?area=' + area +
            '&sector=' + sector +
            '&oficina=' + oficina +
            '&equipo=' + equipo).pipe(map((res) => convertJsonDates(res) as IPersonalOrganigramaDTO ));
   }

   public getPersonalLegajosIDCargoSupervisorSugerido(iDCargo: number): Observable<PersonalLegajoBasicoDTO> {
      return this.http.get<PersonalLegajoBasicoDTO>(Constants.apiURL + '/personalLegajos/' + iDCargo + '/supervisorSugerido').pipe(map((res) => convertJsonDates(res) as PersonalLegajoBasicoDTO ));
   }

   public deletePersonalLegajosIdDNI(id: number, dNI: string): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/personalLegajos/' + id + '/' + dNI);
   }
   public insertPersonalLegajosIdDeclaraciondomicilio(id: number): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/personalLegajos/' + id + '/declaracion-domicilio', null);
   }
   public insertPersonalLegajosIdCertificadoempleoIdCargo(id: number, idCargo: number): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/personalLegajos/' + id + '/certificado-empleo/' + idCargo, null);
   }
   public getPersonalLegajosComunicadoIDCOMUNICADO(idComunicado: number): Observable<NotificationDTO[]> {
      return this.http.get<DataDTO<NotificationDTO>>(Constants.apiURL + '/personalLegajos/comunicado/' + idComunicado).pipe(map((res) => convertJsonDates(res.data) as NotificationDTO[]));
   }

   public getPersonalLegajosIdArchivosConFirmas(params: { id: number, fechaDesde: Date, fechaHasta: Date, period: Date, pageSize: number, pageNumber: number, vistaColaborador?: boolean, sinArchivos?: boolean, documentTypeId?: number, type?: SignatureType, signaturesDone?: boolean }, ): Observable<EstadisticasFirmasDTO> {
      const { id, fechaDesde, fechaHasta, period, pageSize, pageNumber, vistaColaborador = false, sinArchivos = false, documentTypeId = 0, type = undefined, signaturesDone = false } = params;
      return this.http.get<EstadisticasFirmasDTO>(Constants.apiURL + '/personalLegajos/' + id + '/archivosConFirmas' + '?fechaDesde=' + (fechaDesde?.toISOString() ?? '') +
            '&fechaHasta=' + (fechaHasta?.toISOString() ?? '') +
            '&period=' + (period?.toISOString() ?? '') +
            '&pageSize=' + pageSize +
            '&pageNumber=' + pageNumber +
            '&vistaColaborador=' + vistaColaborador +
            '&sinArchivos=' + sinArchivos +
            '&documentTypeId=' + documentTypeId +
            '&type=' + type +
            '&signaturesDone=' + signaturesDone).pipe(map((res) => convertJsonDates(res) as EstadisticasFirmasDTO ));
   }

   public insertPersonalLegajosIdAttachmentsIdAttachmentRecordarFirma(id: number, idAttachment: number, notificationDTO: NotificationDTO): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/personalLegajos/' + id + '/attachments/' + idAttachment + '/recordarFirma', notificationDTO);
   }
   public insertPersonalLegajosRecordarFirmas(notificationDTO: NotificationDTO): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/personalLegajos/recordarFirmas', notificationDTO);
   }
   public async insertPersonalLegajosPropiaFirma(configuracionFirmaDigitalPosicionDTO: ConfiguracionFirmaDigitalPosicionDTO, namedBlob: NamedBlobDTO = null): Promise<Blob> {
      const res = await lastValueFrom(this.http.post(Constants.apiURL +
'/personalLegajos/propiaFirma' + '?BLOB=true', configuracionFirmaDigitalPosicionDTO, { observe: 'response', responseType: 'blob' }));
      const resDTO = new Blob([res.body], { type: 'application/pdf' });
      if (namedBlob) {
         namedBlob.setBlobNameFromHttpResponse(res);
         // eslint-disable-next-line no-param-reassign
         namedBlob.blob = res.body;
      }
      return resDTO;
      }


   public async insertPersonalLegajosPropiaFirmaJPEG(configuracionFirmaDigitalPosicionDTO: ConfiguracionFirmaDigitalPosicionDTO, namedBlob: NamedBlobDTO = null): Promise<Blob> {
      const res = await lastValueFrom(this.http.post(Constants.apiURL +
'/personalLegajos/propiaFirma' + '?BLOB=true', configuracionFirmaDigitalPosicionDTO, { observe: 'response', responseType: 'blob' }));
      const resDTO = new Blob([res.body], { type: 'image/jpeg' });
      if (namedBlob) {
         namedBlob.setBlobNameFromHttpResponse(res);
         // eslint-disable-next-line no-param-reassign
         namedBlob.blob = res.body;
      }
      return resDTO;
      }


   public insertPersonalLegajosIdUsercertificate(id: number): Observable<PersonalLegajoDTO> {
      return this.http.post<PersonalLegajoDTO>(Constants.apiURL + '/personalLegajos/' + id + '/user-certificate', null).pipe(map((res) => convertJsonDates(res) as PersonalLegajoDTO ));
   }

   public getPersonalLegajosIdComentarios(id: number): Observable<ComentarioDTO[]> {
      return this.http.get<DataDTO<ComentarioDTO>>(Constants.apiURL + '/personalLegajos/' + id + '/comentarios').pipe(map((res) => convertJsonDates(res.data) as ComentarioDTO[]));
   }

   public insertPersonalLegajosIdComentarios(id: number, stream: FormData): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/personalLegajos/' + id + '/comentarios', stream);
   }
   public updatePersonalLegajosIdComentariosIDCOMENTARIO(id: number, iDComentario: number, comentarioDTO: ComentarioDTO): Observable<ComentarioDTO> {
      return this.http.put<ComentarioDTO>(Constants.apiURL + '/personalLegajos/' + id + '/comentarios/' + iDComentario, comentarioDTO).pipe(map((res) => convertJsonDates(res) as ComentarioDTO ));
   }

   public deletePersonalLegajosIdComentariosIDCOMENTARIO(id: number, iDComentario: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/personalLegajos/' + id + '/comentarios/' + iDComentario);
   }
   public insertPersonalLegajosVistaFichaje(): Observable<AuthResponseDTO> {
      return this.http.post<AuthResponseDTO>(Constants.apiURL + '/personalLegajos/vistaFichaje', null).pipe(map((res) => convertJsonDates(res) as AuthResponseDTO ));
   }

   public updatePersonalLegajosIdFichajesIDFICHAJE(id: number, iDFichaje: number, fichajeBasicoDTO: FichajeBasicoDTO): Observable<FichajeBasicoDTO> {
      return this.http.put<FichajeBasicoDTO>(Constants.apiURL + '/personalLegajos/' + id + '/fichajes/' + iDFichaje, fichajeBasicoDTO).pipe(map((res) => convertJsonDates(res) as FichajeBasicoDTO ));
   }

   public getPersonalLegajosIdCuentasCorrientes(id: number): Observable<CuentaCorrienteDTO[]> {
      return this.http.get<DataDTO<CuentaCorrienteDTO>>(Constants.apiURL + '/personalLegajos/' + id + '/cuentasCorrientes').pipe(map((res) => convertJsonDates(res.data) as CuentaCorrienteDTO[]));
   }

   public insertPersonalLegajosIdCuentasCorrientes(id: number, cuentaCorrienteDTO: CuentaCorrienteDTO): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/personalLegajos/' + id + '/cuentasCorrientes', cuentaCorrienteDTO);
   }
   public getPersonalLegajosIdSaldoAusenciaIDAusencia(id: number, iDAusencia: number): Observable<SaldoCuentaCorrienteDTO> {
      return this.http.get<SaldoCuentaCorrienteDTO>(Constants.apiURL + '/personalLegajos/' + id + '/saldoAusencia/' + iDAusencia).pipe(map((res) => convertJsonDates(res) as SaldoCuentaCorrienteDTO ));
   }

   public getPersonalLegajosFeedbackRecibidos(pageNumber: number, pageSize: number): Observable<PersonalFeedbackDTO[]> {
      return this.http.get<DataDTO<PersonalFeedbackDTO>>(Constants.apiURL + '/personalLegajos/feedback/recibidos' + '?pageNumber=' + pageNumber +
            '&pageSize=' + pageSize).pipe(map((res) => convertJsonDates(res.data) as PersonalFeedbackDTO[]));
   }

   public getPersonalLegajosFeedbackEnviados(pageNumber: number, pageSize: number): Observable<PersonalFeedbackDTO[]> {
      return this.http.get<DataDTO<PersonalFeedbackDTO>>(Constants.apiURL + '/personalLegajos/feedback/enviados' + '?pageNumber=' + pageNumber +
            '&pageSize=' + pageSize).pipe(map((res) => convertJsonDates(res.data) as PersonalFeedbackDTO[]));
   }

   public getPersonalLegajosIdFeedbackResumen(id: number): Observable<PersonalFeedbackResumenDTO[]> {
      return this.http.get<DataDTO<PersonalFeedbackResumenDTO>>(Constants.apiURL + '/personalLegajos/' + id + '/feedback/resumen').pipe(map((res) => convertJsonDates(res.data) as PersonalFeedbackResumenDTO[]));
   }

   public getPersonalLegajosIdFeedbackRecibidos(id: number, pageNumber: number, pageSize: number): Observable<PersonalFeedbackDTO[]> {
      return this.http.get<DataDTO<PersonalFeedbackDTO>>(Constants.apiURL + '/personalLegajos/' + id + '/feedback/recibidos' + '?pageNumber=' + pageNumber +
            '&pageSize=' + pageSize).pipe(map((res) => convertJsonDates(res.data) as PersonalFeedbackDTO[]));
   }

   public getPersonalLegajosIdFeedbackEnviados(id: number, pageNumber: number, pageSize: number): Observable<PersonalFeedbackDTO[]> {
      return this.http.get<DataDTO<PersonalFeedbackDTO>>(Constants.apiURL + '/personalLegajos/' + id + '/feedback/enviados' + '?pageNumber=' + pageNumber +
            '&pageSize=' + pageSize).pipe(map((res) => convertJsonDates(res.data) as PersonalFeedbackDTO[]));
   }

   public insertPersonalLegajosReferir(referidoDTO: ReferidoDTO): Observable<ReferidoDTO> {
      return this.http.post<ReferidoDTO>(Constants.apiURL + '/personalLegajos/referir', referidoDTO).pipe(map((res) => convertJsonDates(res) as ReferidoDTO ));
   }

   public insertPersonalLegajosWorkdayidWorkDayBulk(idWorkDay: number, assignmentType: AssignmentType): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/personalLegajos/work-day/' + idWorkDay + '/bulk' + '?assignmentType=' + assignmentType, null);
   }
   public updatePersonalLegajosContractdueContractId(contractId: number): Observable<void> {
      return this.http.put<void>(Constants.apiURL + '/personalLegajos/contract-due/' + contractId, null);
   }
   public getPersonalLegajosProfileedithistory(params: { pageNumber: number, pageSize: number, searchValue?: string, sortActive?: number, isAsc?: boolean }, ): Observable<ProfileEditDTO[]> {
      const { pageNumber, pageSize, searchValue = '', sortActive = 0, isAsc = false } = params;
      return this.http.get<DataDTO<ProfileEditDTO>>(Constants.apiURL + '/personalLegajos/profile-edit-history' + '?pageNumber=' + pageNumber +
            '&pageSize=' + pageSize +
            '&searchValue=' + searchValue +
            '&sortActive=' + sortActive +
            '&isAsc=' + isAsc).pipe(map((res) => convertJsonDates(res.data) as ProfileEditDTO[]));
   }
}
